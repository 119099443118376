<template>
  <div>
    Loading...
  </div>
</template>
<script>
import { info, getTransaction } from '../api/2fa'
import lib from '@/lib'

export default {
  name: 'Redirect',
  data () {
    return {
      mode: 'verify',
      lang: ['en', 'zh'],
      tmr: null,
      url: 'https://www.owlting.com'
    }
  },
  async created () {
    if (!Object.prototype.hasOwnProperty.call(this.$route.query, 'jwt')) {
      this.$router.push({ path: '/' })
    }

    if (this.$route.query.jwt === undefined) {
      this.$router.push({ path: '/' })
    }
    this.$store.dispatch('setJWT', this.$route.query.jwt)
    this.$store.dispatch('setMode', this.mode)
    localStorage.removeItem('targetTime')

    const monitor = (cb, target, interval = 5000) => {
      const getCookie = function (name) {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) return parts.pop().split(';').shift()
      }
      let lastCookie = getCookie(target)
      this.tmr = setInterval(() => {
        const cookie = getCookie(target)
        if (cookie !== lastCookie) {
          try {
            cb(lastCookie, cookie)
          } catch (e) {
            console.log(e)
          } finally {
            lastCookie = cookie
          }
        }
      }, interval)
    }

    monitor(async (o, n) => {
      const ret = await getTransaction({ token: o })
      clearInterval(this.tmr)
      alert(this.$t('page_invalid'))
      if (!ret.status) {
        window.location.href = this.url
      }
      if (ret.data.transaction.type === 'setting') {
        window.location.href = ret.data.transaction.redirect
      } else {
        const data = {
          token: o,
          tid: ret.data.transaction.tid
        }
        lib.post_to_url(ret.data.transaction.redirect, data)
      }
    }, '2FA-Token')

    const retInfo = await info()
    let lang = retInfo.data.customer.lang.split('-')
    lang = lang[0]
    if (!this.lang.includes(lang)) {
      lang = 'en'
    }
    this.$i18n.locale = lang
    this.$store.dispatch('setLanguage', lang)

    this.$router.push({ name: 'verify.index' })
  }
}
</script>
